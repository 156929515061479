import {
	Alert,
	AlertTitle,
	Box,
	Container,
	Divider,
	Grid,
	Stack,
	Typography,
	useMediaQuery,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/Header";
import Sanefa from "../../../components/Sanefa";
import { makeStyles } from "@mui/styles";
import theme from "../../../theme";
import { useLocation, useNavigate, useParams } from "react-router";
import CustomPublicButton from "../../../components/CustomPublicButton";
import Page from "../../../components/Layout/Page";
import { useTranslation } from "react-i18next";
import Icons from "./components/Icons";
import { ReactComponent as Aproximacio } from "../../../assets/img/icons/aproximacio.svg";
import { ReactComponent as Dificultat1 } from "../../../assets/img/icons/dificultat1.svg";
import { ReactComponent as Dificultat2 } from "../../../assets/img/icons/dificultat2.svg";
import { ReactComponent as Dificultat3 } from "../../../assets/img/icons/dificultat3.svg";
import { ReactComponent as Desnivell } from "../../../assets/img/icons/desnivell.svg";
import { ReactComponent as Llargada } from "../../../assets/img/icons/llargada.svg";
import { ReactComponent as Temps } from "../../../assets/img/icons/temps.svg";
import { ReactComponent as Acuatic } from "../../../assets/img/icons/acuatic.svg";
import { ReactComponent as Barranquisme } from "../../../assets/img/icons/barranquisme.svg";
import { ReactComponent as Escalada } from "../../../assets/img/icons/escalada.svg";
import { ReactComponent as Ruta } from "../../../assets/img/icons/ruta.svg";
import { ReactComponent as Via } from "../../../assets/img/icons/via.svg";
import { ReactComponent as Pendol } from "../../../assets/img/logo-icono-pendol.svg";
import { Album } from "./components/Album";

const EspaiSingle = () => {
	const classes = useStyles();
	const { state } = useLocation();
	let navigate = useNavigate();
	const { t, i18n } = useTranslation();

	return (
		<Page
			className={classes.main}
			title={"Espai Actiu Vallcebre - " + (state?.nom ? state?.nom : "")}
		>
			<Header img={state?.foto} title={state.nom} />
			<Sanefa color={"white"} />
			<Box className={classes.activitats}>
				<Container>
					{state.id === 1 && (
						<Alert severity="warning">
							<AlertTitle>
								Espai tancat el dia 1 de juliol
							</AlertTitle>
							A causa de l'activitat fisicoesportiva CATRAID
							VALLCEBRE, l'espai romandrà tancat el dia 1 de
							juliol
						</Alert>
					)}
					<Box mt={1}>
						<Box
							display="flex"
							justifyContent={"space-between"}
							alignItems="center"
							mb={4}
						>
							<Box display="flex" alignItems="center">
								<Cat nom={state?.cat_nom} />
								<Typography
									variant="h2"
									textTransform="uppercase"
									color={theme.palette.text.secondary}
								>
									{t(state?.cat_nom)}
								</Typography>
							</Box>

							<Box className={classes.pagament}>
								<Typography
									variant="h6"
									className={
										state?.pagament
											? classes.textPagament
											: classes.textGratuit
									}
									fontSize={15}
									onClick={() => navigate("/entrades")}
								>
									{state?.pagament
										? t("Accés de pagament")
										: t("Accés gratuït!")}
								</Typography>
							</Box>
						</Box>
						<Divider />
						<Box
							mt={3}
							display="flex"
							alignItems="center"
							justifyContent={"center"}
						>
							<Box mb={5}>
								<Typography>{t(state?.desc)}</Typography>
							</Box>
						</Box>
						<Box
							display="flex"
							alignItems="center"
							justifyContent="space-between"
							mb={5}
						>
							<Icons
								Icon={Aproximacio}
								value={state?.aproximacio}
								append="min"
								nom="Aproximació"
								big
							/>
							<Icons
								Icon={
									state?.dificultat === "F" ||
									state?.dificultat === "K1"
										? Dificultat1
										: state?.dificultat === "D" ||
										  state?.dificultat === "K3"
										? Dificultat2
										: Dificultat3
								}
								value={state?.dificultat}
								nom="Dificultat"
								big
							/>
							<Icons
								Icon={Desnivell}
								value={state?.desnivell}
								append="m"
								nom="Desnivell"
								big
							/>
							<Icons
								Icon={Llargada}
								value={state?.llargada}
								append="m"
								nom="Llargada"
								big
							/>
							<Icons
								Icon={Temps}
								value={state?.temps}
								append="min"
								nom="Temps"
								big
							/>
						</Box>

						<Stack
							direction={"row"}
							spacing={2}
							justifyContent="center"
							my={9}
						>
							{state?.cat_nom !== "Ruta" && (
								<CustomPublicButton
									title={t("LLOGUER MATERIAL")}
									href={"https://pendolguies.com/lloguer"}
									Icon={Pendol}
									target="_blank"
								/>
							)}
							{state?.cat_nom !== "Acuàtic" && (
								<CustomPublicButton
									title={t("CONTRACTA GUIATGE")}
									href={state?.guiada}
									Icon={Pendol}
									target="_blank"
								/>
							)}
						</Stack>
						<Typography variant="caption">
							{t(
								"Si es contracta un guiatge des de Pèndol Guies, la entrada a l'espai està inclosa"
							)}
						</Typography>
						<Box className={classes.mapa}>
							<Typography variant="h3" p={2} color="white">
								{t("Mapa")}
							</Typography>
							<img
								alt="Mapa Vallcebre"
								src={state?.mapa}
								className={classes.mapaImg}
							/>
						</Box>
						{state?.mes && (
							<Box textAlign={"center"} mt={4}>
								<CustomPublicButton
									title={t("MÉS INFORMACIÓ")}
									href={state?.mes}
									// download
									target="_blank"
								/>
							</Box>
						)}
						{state?.fotos && <Album photos={state?.fotos} />}
					</Box>
				</Container>
			</Box>
		</Page>
	);
};

const Cat = ({ nom }) => {
	const classes = useStyles();
	let Cat = "";
	switch (nom) {
		case "Via ferrada":
			Cat = Via;
			break;
		case "Barranquisme":
			Cat = Barranquisme;
			break;
		case "Ruta":
			Cat = Ruta;
			break;
		case "Acuàtic":
			Cat = Acuatic;
			break;
		case "Escalada":
			Cat = Escalada;
			break;
		default:
			break;
	}
	return (
		<Box
			display={"flex"}
			flexDirection="column"
			alignItems={"center"}
			className={classes.cat}
		>
			<Cat className={classes.catIcon} />
		</Box>
	);
};

export default EspaiSingle;

const useStyles = makeStyles((theme) => ({
	main: {
		overflowX: "hidden",
	},
	filters: {
		paddingTop: 100,
		paddingBottom: 180,
	},
	activitats: {
		paddingTop: 50,
		paddingBottom: 250,
		// background: theme.palette.background.gradient,
		marginTop: -5,
	},
	buscant: {
		paddingTop: 150,
		paddingBottom: 150,
		textAlign: "center",
	},
	header: {
		border: "1px solid white",
		borderRadius: 50,
		padding: 15,
		paddingLeft: 20,
	},
	label: {
		color: "white",
		fontWeight: 800,
	},
	value: {
		color: "white",
	},
	imageWrap: {
		height: 200,
	},
	slide: {
		paddingBottom: 50,
		paddingTop: 50,
		"& .alice-carousel__stage-item div": {
			width: "100%",
		},
	},
	left: {
		position: "absolute",
		left: -60,
		top: "35%",
		width: 64,
		height: 64,
	},
	right: {
		position: "absolute",
		right: -60,
		top: "35%",
		width: 64,
		height: 64,
	},
	mapa: {
		border: "1px solid " + theme.palette.primary.main,
		borderRadius: 20,
		overflow: "hidden",
		backgroundColor: theme.palette.primary.main,
	},
	mapaImg: {
		width: "100%",
	},
	pagament: {
		width: 60,
		height: 60,
		padding: 5,
		backgroundColor: "black",
		borderRadius: 50,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		zIndex: 10,
		cursor: "pointer",
		transition: "0.2s",
		"&:hover": {
			transform: "scale(1.2)",
		},
	},
	textPagament: {
		color: "#F29300 !important",
		textAlign: "center",
		lineHeight: "13px !important",
		textTransform: "none !important",
	},
	textGratuit: {
		color: theme.palette.secondary.main,
		textAlign: "center",
		lineHeight: "13px !important",
		textTransform: "none !important",
	},
	cat: {
		padding: 10,
		// borderBottomRightRadius: 30,
	},
	catIcon: {
		width: 70,
		height: 70,
		fill: theme.palette.secondary.main,
	},
}));
