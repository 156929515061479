import {
	Alert,
	AlertTitle,
	Box,
	Checkbox,
	CircularProgress,
	Container,
	FormControlLabel,
	Grid,
	Tooltip,
	Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Header from "../../../components/Header";
import img from "../../../assets/img/espais/barranquisme.jpg";
import Sanefa from "../../../components/Sanefa";
import { makeStyles } from "@mui/styles";
import IconList from "../../../components/Activitats/IconList";
import { Activity, Calendar, MapPin } from "react-feather";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaRes } from "../../../components/Schemas";
import CustomPublicField from "../../../components/Activitats/CustomPublicField";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import ca from "date-fns/locale/ca";
import moment from "moment";
import "../../../theme/styles/style.css";
import i18n from "../../../lang";
import CustomPublicButton from "../../../components/CustomPublicButton";
import {
	createEntrada,
	createReserva,
	getElements,
	getPrice,
} from "../../../database/API";
import Page from "../../../components/Layout/Page";
import parse from "html-react-parser";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import EntradesPersones from "./EntradesPersones";
import EntradesDialogAddPersona from "./EntradesDialogAddPersona";
import CustomSelect from "../../../components/CustomSelect";
import DialogCalendari from "./DialogCalendari";
import { getDate } from "date-fns";
import { quinaDisponibilitat, teDisponibilitat } from "../../../data/Utils";
import dies from "../../../data/TipusDies";
import preusEntrada from "../../../data/PreusData";

registerLocale("es", es);
registerLocale("ca", ca);

const Entrades = () => {
	const classes = useStyles();
	const [date, setDate] = useState("");
	const [preu, setPreu] = useState(0);
	const [loading, setLoading] = useState(false);
	const [open, setOpen] = useState(false);
	const [openCal, setOpenCal] = useState(false);
	const [formCompra, setForm] = useState();
	const [exclude, setExclude] = useState([]);
	const [calendari, setCalendari] = useState([]);
	const { schemaReserva } = SchemaRes();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		watch,
		control,
	} = useForm({
		mode: "onTouched",
		resolver: yupResolver(schemaReserva),
	});

	const personesForm = useFieldArray({
		control,
		name: "persones",
	});

	useEffect(() => {
		const get = async () => {
			setLoading(true);
			const calendaris = await getElements("calendaris");
			setCalendari(calendaris.result);
			console.log(calendaris.result);
			let ex = [];
			calendaris.result.map(
				(i) =>
					i.disponibilitat === "4" && ex.push(moment(i.data).toDate())
			);
			// Object.entries(calendaris.result).forEach(([key, value]) => {
			// 	ex.push(moment(key).toDate());
			// });
			console.log(ex);
			setExclude(ex);
			setLoading(false);
		};

		get();
	}, []);

	useEffect(() => {
		const subscription = watch((value, { name }) => {
			if (name === "access" || name === "persones") {
				let p = 0;
				value.persones?.map((item) => {
					const listPreus = preusEntrada?.filter(
						(e) => e.id === Number(value.access)
					)[0]?.preus;

					p =
						p +
						listPreus?.filter((i) => i.nom === item.federat)[0]
							.preu;
				});
				setPreu(p);
			}
		});
		return () => subscription.unsubscribe();
	}, [watch]);

	const enviar = async (values) => {
		values.preu = preu;

		// setLoading(true);
		const { form, message } = await createEntrada(values);
		setForm(form);

		enqueueSnackbar(message, {
			variant: "success",
		});
		setTimeout(() => document.forms["redsys_form"].submit(), 500);
		reset();
		setLoading(false);
	};

	const onChangeDate = (date) => {
		setValue("data", moment(date[0]).format("YYYY-MM-DD"));
		setDate(date[0]);
	};

	const renderDia = (day, date) => {
		return (
			<Tooltip
				title={
					dies.filter(
						(i) =>
							i.id ===
							Number(quinaDisponibilitat(moment(date), calendari))
					)?.[0]?.text
				}
			>
				<Box style={{ position: "relative" }}>
					<Box height={15}>{getDate(date)}</Box>
					<Box className={classes.iconCalendar}>
						{teDisponibilitat(moment(date), calendari)}
					</Box>
				</Box>
			</Tooltip>
		);
	};

	return (
		<Page
			title={"Espai Actiu Vallcebre - " + t("Entrades")}
			className={classes.main}
		>
			<Header title={t("Entrades")} img={img} />
			<Sanefa color="white" />

			<Box className={classes.filters}>
				{parse(formCompra ? formCompra.data : "")}

				<form onSubmit={handleSubmit(enviar)}>
					<Container>
						<Alert severity="warning">
							<AlertTitle>
								Via ferrada La Canalassa tancada el dia 1 de
								juliol
							</AlertTitle>
							A causa de l'activitat fisicoesportiva CATRAID
							VALLCEBRE, la via ferrada La Canalassa romandrà
							tancat el dia 1 de juliol
						</Alert>
						<Grid container spacing={5} mt={5}>
							<Grid item md={8} xs={12}>
								<Grid container spacing={3}>
									<Grid item md={12} xs={12}>
										<CustomSelect
											name="access"
											label={t("Tria l'accés")}
											type="text"
											errors={errors.acces}
											register={register}
											elements={preusEntrada}
										/>
										<Typography variant="caption">
											{t(
												"L'entrada general et dona accés a tots els espais. L'entrada barranc, només tindràs accés als espais de barrancs."
											)}
										</Typography>
									</Grid>

									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="nom"
											label={t("Nom i cognoms contacte")}
											type="text"
											errors={errors.nom}
											register={register}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="phone"
											label={t("Telèfon")}
											type="text"
											errors={errors.phone}
											register={register}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="email"
											label="E-mail"
											type="email"
											errors={errors.email}
											register={register}
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<CustomPublicField
											name="observacions"
											label={t("Observacions (opcional)")}
											type="text"
											errors={errors.observacions}
											register={register}
											rows={9}
											multiline
										/>
									</Grid>
									<Grid item md={12} xs={12}>
										<FormControlLabel
											control={
												<Checkbox
													onChange={(e) => {}}
													name="accepta"
													{...register("accepta")}
													required
												/>
											}
											label={
												<Typography>
													{t(
														"He llegit i accepto la"
													)}{" "}
													<a
														href={require("../../../assets/docs/normativa.pdf")}
														target="_blank"
														alt="Política de Privacitat"
														rel="noreferrer"
													>
														{t("Normativa")}
													</a>
													{", "}
													<a
														href="/politica-privacitat"
														target="_blank"
														alt="Política de Privacitat"
														rel="noreferrer"
													>
														{t(
															"Política de Privacitat"
														)}
													</a>
													{t(" i les ")}
													<a
														href="/condicions-activitats"
														target="_blank"
														alt="Condicions d'activitat"
														rel="noreferrer"
													>
														{t(
															"Condicions activitats"
														)}
													</a>
												</Typography>
											}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item md={4}>
								<EntradesPersones
									personesForm={personesForm}
									setOpen={setOpen}
									watch={watch}
									errors={errors}
								/>
								<Box className={classes.blockDate}>
									<Typography
										variant="h6"
										textTransform="uppercase"
										pl={2}
									>
										{t("Seleccionar data")}
									</Typography>
									<DatePicker
										onChange={onChangeDate}
										selected={date}
										selectsRange
										selectsDisabledDaysInRange
										dateFormat="dd/MM/yyyy"
										minDate={new Date()}
										inline
										locale={i18n.language}
										renderDayContents={renderDia}
										excludeDates={exclude}
									/>
									<Box
										justifyContent={"center"}
										display="flex"
										mb={3}
									>
										<IconList
											label={
												date
													? moment(date).format(
															"DD/MM/YYYY"
													  )
													: "..."
											}
											icon={<Calendar />}
											pb={2}
										/>
										{errors?.data ? (
											<Typography variant="error">
												{errors?.data?.message}
											</Typography>
										) : (
											""
										)}
									</Box>
									<Box px={4} pb={2}>
										<CustomPublicButton
											title={t(
												"Calendari anual disponibilitat"
											)}
											onClick={() => setOpenCal(true)}
											small
											fullWidth
										/>
									</Box>
								</Box>
							</Grid>
						</Grid>
						<Box
							className={classes.blockTotal}
							display="flex"
							justifyContent="space-between"
							alignItems="center"
						>
							<Box>
								<Typography
									variant="h1"
									fontSize={30}
									color="white"
									display="flex"
								>
									{t("Total")}:{" "}
									{loading ? (
										<CircularProgress
											color="secondary"
											size={30}
											style={{ marginLeft: 20 }}
										/>
									) : (
										preu + "€"
									)}
								</Typography>
							</Box>
							<CustomPublicButton
								type="submit"
								title={t("Reservar i pagar")}
								loading={loading}
							/>
						</Box>
					</Container>
					<EntradesDialogAddPersona
						open={open}
						setOpen={setOpen}
						personesForm={personesForm}
					/>
				</form>
				<DialogCalendari open={openCal} setOpen={setOpenCal} />
			</Box>
		</Page>
	);
};

export default Entrades;

const useStyles = makeStyles((theme) => ({
	filters: {
		paddingTop: 100,
		paddingBottom: 200,
	},
	block: {
		border: "1px solid " + theme.palette.background.third,
		borderRadius: 5,
		padding: 20,
		marginBottom: 20,
	},

	blockTotal: {
		backgroundColor: theme.palette.background.main,
		borderRadius: 5,
		padding: 20,
		marginBottom: 20,
	},

	blockDate: {
		border: "1px solid " + theme.palette.background.third,
		borderRadius: 5,
		paddingTop: 20,
		marginBottom: 20,
	},
}));
